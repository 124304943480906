<template>
    <div class="bridge">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">连心桥</div>
            </div>
            <div class="flex flex-end flex-1" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="bridge-box flex flex-justify-between">
                <div class="bridge-left color_FF" id="bridge-left">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                    >
                        <div
                            class="bridge-left-list flex"
                            v-for="(item, id) in bridgeLeftList"
                            :key="id"
                            @click="goToLeftBridgeDetail(item)"
                        >
                            <img
                                :src="item.cover"
                                alt=""
                                class="bridge-left-list-pic m_r20"
                            />
                            <div class="flex flex-v flex-justify-center">
                                <p class="m_b30 font-size24 font-blod">
                                    {{ item.title }}
                                </p>
                                <p class="font-size18 color_9FB">
                                    {{ item.releaseTime }}
                                </p>
                            </div>
                        </div>
                    </van-list>
                </div>
                <div class="bridge-right">
                    <p
                        class="
                            m_b30
                            bridge-right-title
                            font-blod font-size28
                            color_FF
                        "
                    >
                        重要访谈
                    </p>
                    <div class="bridge-right-box" id="bridge-right">
                        <van-list
                            v-model="rightLoading"
                            :finished="rightFinished"
                            finished-text="没有更多了"
                            @load="onRightLoad"
                        >
                            <div
                                class="bridge-right-list"
                                v-for="(item, id) in bridgeRightList"
                                :key="id"
                                @click="goToRightBridgeDetail(item)"
                            >
                                <img
                                    :src="item.cover"
                                    alt=""
                                    class="bridge-right-pic"
                                />
                                <p class="font-size24 font-blod m_b30">
                                    {{ item.title }}
                                </p>
                                <p
                                    class="
                                        bridge-right-list-more
                                        my_text_center
                                        font-size18
                                    "
                                >
                                    <span>查看详情</span>
                                </p>
                            </div>
                        </van-list>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF">
            </div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getNewsList } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            bridgeLeftList: [],
            bridgeRightList: [],
            pageNum: 1,
            pageSize: 6,
            totalPage: null,
            rightPageNum: 1,
            rightPageSize: 6,
            rightTotalPage: null,
            loading: false,
            finished: false,
            rightLoading: false,
            rightFinished: false,
            scrollTopLeft: 0,
            scrollTopRight: 0,
        };
    },
    created() {
        this.getTime();
    },
    activated() {
        document.getElementById("bridge-left").scrollTop = this.scrollTopLeft; // 设置滚动条位置
        document.getElementById("bridge-right").scrollTop = this.scrollTopRight; // 设置滚动条位置
    },
    mounted() {
        this.getLeftFetch();
        this.getRightList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        getLeftFetch() {
            getNewsList({
                tid: 6,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                status: 1,
            }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    let data = res.data.list;

                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                        data.forEach((item) => {
                            item.createTime = item.createTime.split(" ")[0];
                        });
                        this.totalPage = res.data.totalPage;
                        this.bridgeLeftList.push(...data);
                    }
                }
            });
        },

        getRightList() {
            getNewsList({
                tid: 7,
                pageNum: this.rightPageNum,
                pageSize: this.rightPageSize,
                status: 1,
            }).then((res) => {
                this.rightLoading = false;
                if (res.code == 200) {
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.rightFinished = true;
                    } else {
                        if (this.rightPageNum == res.data.totalPage) {
                            this.rightFinished = true;
                        } else {
                            this.rightFinished = false;
                        }
                        data.forEach((item) => {
                            item.createTime = item.createTime.split(" ")[0];
                        });
                        this.rightTotalPage = res.data.totalPage;
                        this.bridgeRightList.push(...data);
                    }
                }
            });
        },

        //左边列表跳转到详情页
        goToLeftBridgeDetail(data) {
            this.scrollTopLeft =
                document.getElementById("bridge-left").scrollTop;
            let Data = JSON.stringify(data);
            this.$router.push({
                name: "supervisedetail",
                params: { data: encodeURIComponent(Data), type: 3 },
            });
        },

        //右边列表跳转详情
        goToRightBridgeDetail(data) {
            this.scrollTopRight =
                document.getElementById("bridge-right").scrollTop;

            let Data = JSON.stringify(data);
            this.$router.push({
                name: "supervisedetail",
                params: { data: encodeURIComponent(Data), type: 3 },
            });
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },

        onLoad() {
            if (this.totalPage <= this.pageNum) {
                this.loading = false;
                this.finished = true;
            } else {
                this.loading = true;
                this.pageNum++;
                this.getLeftFetch();
            }
        },

        onRightLoad() {
            if (this.rightTotalPage <= this.rightPageNum) {
                this.rightLoading = false;
                this.rightFinished = true;
            } else {
                this.rightLoading = true;
                this.rightPageNum++;
                this.getRightList();
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.path === "/supervisedetail") {
            from.meta.keepAlive = true;
        } else {
            from.meta.keepAlive = false;
        }
        next();
    },
};
</script>
